// extracted by mini-css-extract-plugin
export var column = "UserCloudRackManageButton__column__awl_C";
export var flex = "UserCloudRackManageButton__flex__jlDcd";
export var flexColumn = "UserCloudRackManageButton__flexColumn__I0CgV";
export var gap1 = "UserCloudRackManageButton__gap1__UnfR_";
export var gap2 = "UserCloudRackManageButton__gap2__D8umz";
export var gap3 = "UserCloudRackManageButton__gap3__dmA6r";
export var gap4 = "UserCloudRackManageButton__gap4__aJDdV";
export var gap5 = "UserCloudRackManageButton__gap5__aYWa_";
export var icon = "UserCloudRackManageButton__icon__QsZll";
export var link = "UserCloudRackManageButton__link__uNn9W";
export var row = "UserCloudRackManageButton__row__Ed9hJ";