// extracted by mini-css-extract-plugin
export var column = "UserCloudRack__column__tIFv2";
export var container = "UserCloudRack__container__VGqdY";
export var contentWrapper = "UserCloudRack__contentWrapper__TrRTA";
export var description = "UserCloudRack__description__jdEqB";
export var flex = "UserCloudRack__flex__K_YBo";
export var flexColumn = "UserCloudRack__flexColumn__q5sst";
export var gap1 = "UserCloudRack__gap1__Ki57b";
export var gap2 = "UserCloudRack__gap2__sVwgo";
export var gap3 = "UserCloudRack__gap3__ZA_AB";
export var gap4 = "UserCloudRack__gap4__TsDhq";
export var gap5 = "UserCloudRack__gap5__D_qUy";
export var row = "UserCloudRack__row__o7VV6";
export var titleMain = "UserCloudRack__titleMain__OK2y1";